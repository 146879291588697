<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Usuarios</h1>
		</div>
		<br />

		<DataGrid :config="dataGridConfig" :data="usuarios" @actions="dataGridActions" :select="seleccionar">
			<div class="col-sm-2">
				<button class="btn warning-btn" @click="obtener_usuarios">Recargar</button>
			</div>
			<div class="col-sm-2">
				<button class="btn complementary-btn" @click="compilar_usuarios">Re-compilar usuarios</button>
			</div>
			<div class="col-sm-2">
				<button v-if="$auth.can('account','crear_usuario')" class="btn principal-btn" @click="abrir_modal(1)">Nuevo</button>
			</div>
			<div class="col-sm-2">
				<button v-if="$auth.can('account','editar_usuario')" class="btn secondary-btn" @click="abrir_modal(2)">Editar</button>
			</div>
			<div class="col-sm-2">
				<button v-if="$auth.can('account','eliminar_usuario')" class="btn danger-btn">Eliminar</button>
			</div>
		</DataGrid>

		<Modal v-if="modal != 0" :options="{width: '80vw'}" @close="cancelar_modal" class="bpb-modal">
			<form @submit="guardar_modal">
				<h2 class="title">Usuario</h2>
				<div class="body">
					<div class="row">
						<div class="col-sm-5">
							<fieldset>
								<legend>Usuario</legend>
								<div class="row form-group">
									<label for="organizacion" class="col-form-label col-sm-2">Organización</label>
									<div v-if="modal==1" class="col-sm-10">
										<select v-model="usuario.organizacion_id" name="organizacion" id="organizacion" class="form-control">
											<option v-for="tmpOrg in organizaciones" :key="tmpOrg.id" :value="tmpOrg.id">{{ tmpOrg.nombre }}</option>
										</select>
									</div>
									<div v-else class="col-sm-10">{{ usuario.organizacion.nombre }}</div>
								</div>
								<div class="row form-group">
									<label for="usuario" class="col-form-label col-sm-2">Usuario</label>
									<div v-if="modal==1" class="col-sm-10"><input v-model="usuario.usuario" type="text" name="usuario" id="usuario" class="form-control"></div>
									<div v-else class="col-sm-10">{{ usuario.usuario }}</div>
								</div>
								<div class="row form-group">
									<label for="password" class="col-form-label col-sm-2">Contraseña</label>
									<div class="col-sm-10"><input v-model="usuario.password" type="password" name="password" id="password" class="form-control"></div>
								</div>
								<div class="row form-group">
									<label for="confirm_password" class="col-form-label col-sm-2">Confirmar contraseña</label>
									<div class="col-sm-10"><input v-model="usuario.password_confirmation" type="password" name="confirm_password" id="confirm_password" class="form-control"></div>
								</div>
								<div class="row form-group">
									<label for="estatus" class="col-form-label col-sm-2">Estatus</label>
									<div class="col-sm-10">
										<select v-model="usuario.estatus" name="estatus" id="estatus" class="form-control">
											<option value="1">Activo</option>
											<option value="0">Inactivo</option>
										</select>
									</div>
								</div>
								<div class="row form-group">
									<label for="" class="col-form-label col-sm-2">Avatar</label>
									<div class="col-sm-10">
										<div class="avatar-container">
											<img ref="preview_avatar" class="avatar-img" src="https://static.bienparabien.com/img/icons/user.png" alt="Avatar">
											<input ref="file_avatar" type="file" name="file_avatar" id="file_avatar" class="avatar-control" accept=".jpg,.png,.jpeg,." @change="preview_avatar">
											<div class="avatar-action" @click="$refs.file_avatar.click()">
												<div class="avatar-icon">
													<i class="gg-pen"></i>
												</div>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
						<div class="col-sm-7">
							<fieldset>
								<legend>Identidad <small style="font-size: 0.7em;">(opcionales)</small></legend>

								<div class="row form-group">
									<label for="curp" class="col-form-label col-sm-2">Nombre</label>
									<div class="col-sm-10"><input v-model="usuario.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
								</div>
								<div class="row form-group">
									<label for="curp" class="col-form-label col-sm-2">CURP</label>
									<div class="col-sm-10"><input v-model="usuario.identidad.curp" type="text" name="curp" id="curp" class="form-control"></div>
								</div>
								<div class="row form-group">
									<label for="rfc" class="col-form-label col-sm-2">RFC</label>
									<div class="col-sm-10"><input v-model="usuario.identidad.rfc" type="text" name="rfc" id="rfc" class="form-control"></div>
								</div>
								<div class="row form-group">
									<label for="" class="col-form-label col-sm-2">Género</label>
									<div class="col-sm-10">
										<Radio v-model="usuario.identidad.sexo" name="genero" id="genero_femenino" value="femenino" :other="{display:'inline'}" label="Femenino" />
										<Radio v-model="usuario.identidad.sexo" name="genero" id="genero_masculino" value="masculino" :other="{display:'inline'}" label="Masculino" />
									</div>
								</div>
								<div class="row form-group">
									<label for="nacionalidad" class="col-form-label col-sm-2">Nacionalidad</label>
									<div class="col-sm-10"><input v-model="usuario.identidad.nacionalidad" type="text" name="nacionalidad" id="nacionalidad" class="form-control"></div>
								</div>
								<div class="row form-group">
									<label for="estado_civil" class="col-form-label col-sm-2">Estado civil</label>
									<div class="col-sm-10"><input v-model="usuario.identidad.estado_civil" type="text" name="estado_civil" id="estado_civil" class="form-control"></div>
								</div>
								<div class="row form-group">
									<label for="fecha_nacimiento" class="col-form-label col-sm-2">Fecha nacimiento</label>
									<div class="col-sm-10"><input v-model="usuario.identidad.fecha_nacimiento" type="date" name="fecha_nacimiento" id="fecha_nacimiento" class="form-control"></div>
								</div>
							</fieldset>
						</div>
					</div>

					<fieldset>
						<legend>Métodos de contacto</legend>

						<div class="row align-items-center list_metodos_header">
							<div class="col-sm-4">Tipo</div>
							<div class="col-sm-4">Valor</div>
							<div class="col-sm-1">Principal</div>
							<div class="col-sm-1">Validado</div>
						</div>

						<div v-for="(contacto, index) in usuario.contactos" :key="index" class="row align-items-center list_metodos_contactos">
							<div class="col-sm-4">{{ tipo_contacto(contacto.tipo) }}</div>
							<div class="col-sm-4">{{ (contacto.tipo != 'email' ? '+52-' : '')+contacto.valor }}</div>
							<div class="col-sm-1">{{ contacto.principal ? 'Principal' : 'Secundario' }}</div>
							<div class="col-sm-1">{{ contacto.validado == 1 ? 'Validado' : 'Sin validar' }}</div>
							<div class="col-sm-1"><button type="button" class="btn secondary-btn btn-contacto" title="Editar" @click="editar_contacto(contacto)"><i class="gg-pen"></i></button></div>
							<div class="col-sm-1"><button type="button" class="btn danger-btn btn-contacto" title="Eliminar" @click="eliminar_contacto_modal(contacto)"><i class="gg-trash-empty"></i></button></div>
						</div>

						<div class="row form-group mt-4">
							<div class="col-sm-3">
								<select v-model="contacto_nuevo.tipo" name="contacto_nuevo_tipo" id="contacto_nuevo_tipo" class="form-control" :disabled="contacto_nuevo.accion==2">
									<option v-for="(texto, tipo, index) in contactos_tipos" :key="index" :value="tipo">{{ texto }}</option>
								</select>
							</div>
							<div class="col-sm-5">
								<div class="input-group">
									<div v-if="contacto_nuevo.tipo != 'email'" class="input-group-prepend"><span class="input-group-text">+52</span></div>
									<input v-model="contacto_nuevo.valor" type="text" name="" id="" class="form-control" :placeholder="contacto_nuevo.tipo == 'email' ? 'correo@dominio.com' : 'número a 10 dígitos sin espacios ni caracteres especiales'">
									<div v-if="['email','celular'].indexOf(contacto_nuevo.tipo) > -1" class="input-group-append">
										<button type="button" class="btn btn-danger" :disabled="contacto_nuevo.validado == 1" @click="otp_modal">Validar OTP</button>
									</div>
								</div>
							</div>
							<div class="col-sm-2">
								<BSwitch v-model="contacto_nuevo.principal" id="contacto_primario" name="contacto_primario" textoff="No" texton="Sí" value="1" label="Correo principal"></BSwitch>
							</div>
							<div class="col-sm-2">
								<button type="button" class="btn secondary-btn" @click="agregar_contacto_nuevo">{{ contacto_nuevo.accion == 1 ? 'Agregar' : 'Actualizar' }}</button>
							</div>
						</div>
					</fieldset>

					<fieldset>
						<legend>Ayudas</legend>

						<Checkbox v-model="usuario.password_reset" name="password_reset" id="password_reset" type="secondary" value="1" label="Solicitar resetar contraseña"/>
						<Checkbox v-model="usuario.notificacion" name="notificacion" id="notificacion" type="secondary" value="1" label="Notificar al usuario"/>
						<Checkbox v-if="modal == 1" v-model="usuario.password_automatico" name="password_automatico" id="password_automatico" value="1" type="secondary" label="Generar contraseña"/>
					</fieldset>

					<fieldset>
						<legend>Roles</legend>

						<div class="row form-group">
							<div class="col-sm-12">
								<div class="container-fluid">
									<Etiqueta v-for="rol in rolesSeleccionados" :key="rol.id" :text="rol.nombre" :value="rol.id" :close="true" @close="quitar_rol" />
								</div>
							</div>
						</div>
						<div class="row form-group">
							<div class="col-sm-3 offset-sm-9">
								<button class="btn principal-btn" type="button" @click="abrir_roles">Agregar rol</button>
							</div>
						</div>
					</fieldset>
				</div>
				<div class="footer">
					<div class="row">
						<div class="col-sm-12 d-flex justify-content-end">
							<div class="col-sm-3">
								<button class="btn secondary-btn" @click="guardar_modal">Guardar</button>
							</div>
							<div class="col-sm-3">
								<button class="btn complementary-btn" type="button" @click="cancelar_modal">Cancelar</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</Modal>

		<Modal v-if="modalRoles" @close="cancelar_roles" class="bpb-modal">
			<form @submit="agregar_roles">
				<div class="title">Agregar rol</div>
				<div class="body">
					<div class="row form-group">
						<label for="roles" class="col-form-label col-sm-2">Rol</label>
						<div class="col-sm-10">
							<select v-model="rol.id" name="roles" id="roles" class="form-control">
								<option v-for="tmpRol in roles_validos" :key="tmpRol.id" :value="tmpRol.id">{{ tmpRol.nombre }}</option>
							</select>
						</div>
					</div>
				</div>
				<div class="footer">
					<div class="row">
						<div class="col-sm-12 d-flex justify-content-end">
							<div class="col-sm-3">
								<button class="btn principal-btn">Agregar</button>
							</div>
							<div class="col-sm-3">
								<button class="btn complementary-btn" @click="cancelar_roles">Cancelar</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</Modal>

		<Modal v-if="otp.mostrar" :options="{width: '30vw'}" @close="cancelar_otp">
			<div class="title">Válidar método de contacto</div>
			<div class="body">
				<div class="row">
					<label for="otp_codigo" class="col-form-label col-sm-2">Código</label>
					<div class="col-sm-10"><input v-model="otp.codigo" type="text" name="otp_codigo" id="otp_codigo" class="form-control"></div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3 offset-sm-6"><button class="btn secondary-btn" @click="otp_validar">Validar</button></div>
					<div class="col-sm-3"><button class="btn danger-btn" @click="cancelar_otp">Cancelar</button></div>
				</div>
			</div>
		</Modal>

		<Modal v-if="eliminar_contacto.mostrar" :options="{width: '30vw',type:'error'}" @close="eliminar_contacto={mostrar:false,id:null,valor:null}">
			<div class="title">Eliminarás un método de contacto</div>
			<div class="body">
				<div class="row">
					<div class="col-sm-12 text-center">
						Eliminarás el método de contacto <strong>{{ eliminar_contacto.valor }}</strong><br/>¿Deseas continuar?
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3 offset-sm-6"><button class="btn secondary-btn" @click="eliminar_metodo_contacto">Continuar</button></div>
					<div class="col-sm-3"><button class="btn danger-btn" @click="eliminar_contacto={mostrar:false,id:null,valor:null}">Cancelar</button></div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGrid'
	import Modal from '@/components/Modal'
	import Checkbox from '@/components/Form/Checkbox'
	import Radio from '@/components/Form/Radio'
	import BSwitch from '@/components/Form/Switch'
	import Etiqueta from '@/components/Etiqueta'

	import api from '@/apps/account/api/account'

	export default {
		components: {
			DataGrid, Modal, Checkbox, Radio, BSwitch, Etiqueta
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'usuarios'
					,cols: {
						id: 'ID'
						,organizacion_id: 'Organizacion'
						,usuario: 'Usuario'
						,nombre: 'Nombre'
						,estatus: 'Estatus'
					}
					,filters: {
						cols: {
							id: 'ID'
							,organizacion_id: {
								text: 'Organizacion'
								,options: {}
							}
							,usuario: 'Usuario'
							,nombre: 'Nombre'
							,estatus: {
								text: 'Estatus'
								,options: {
									1: 'Activo'
									,0: 'Inactivo'
								}
							}
							,created_at: 'Fecha de creación'
						}
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						organizacion_id: function(val,row,vue) {
							return row.organizacion.nombre;
						}
						,estatus: function(val) {
							return val == 1 ? 'Activo' : 'Inactivo';
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,seleccionar: false
				,seleccion: []
				,organizaciones: []
				,roles: []
				,rol: {
					id: 0
					,nombre: null
				}
				,usuarios: []
				,usuario: {
					organizacion_id: 0
					,usuario: null
					,password: null
					,password_confirmation: null
					,nombre: null
					,email: null
					,avatar: null
					,estatus: 1
					,password_reset: 0
					,notificacion: 0
					,password_automatico: 0
					,rol: []
					,password_automatico: false
					,notificacion: false
					,identidad: {
						rfc: null
						,curp: null
						,sexo: null
						,nacionalidad: null
						,estado_civil: null
						,fecha_nacimiento: null
					}
					,contactos: []
				}
				,rolesSeleccionados: []
				,modal: 0
				,modalRoles: false
				,contactos_tipos: {
					email: 'Email'
					,celular: 'Teléfono celular'
					,telefono: 'Teléfono casa'
					,telefono_oficina: 'Teléfono oficina'
				}
				,contacto_nuevo: {
					tipo: 'email'
					,valor: null
					,principal: false
					,validado: false
					,accion: 1
				}
				,otp: {
					mostrar: false
					,destinatario: null
					,codigo: null
				}
				,eliminar_contacto: {
					mostrar: false
					,id: null
					,valor: null
				}
			}
		}
		,mounted: function() {
			this.obtener_usuarios();
			this.obtener_organizaciones();
		}
		,methods: {
			obtener_usuarios: async function() {
				try {
					let response = (await api.listar_usuarios(this.options)).data;

					this.usuarios = response.data;

					this.dataGridConfig.paginator.pagina_actual = response.current_page;
					this.dataGridConfig.paginator.total_registros = response.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(response.per_page);
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,obtener_organizaciones: async function() {
				try {
					if (this.organizaciones.length == 0)
						this.organizaciones = (await api.listar_organizaciones({}, false)).data;

					for(let i=0; i<this.organizaciones.length; i++) {
						this.dataGridConfig.filters.cols.organizacion_id.options[this.organizaciones[i].id] = this.organizaciones[i].nombre;
					}
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,dataGridActions: function(tipo, valor) {
				if (tipo == 'options') {
					this.options = valor;
					this.obtener_usuarios();
				}else {
					this.seleccion = valor;
					this.seleccionar = true;
				}
			}

			//modal
			,cancelar_modal: function(e) {
				if (e) e.preventDefault();

				this.modal = 0;
				this.usuario = {
					organizacion_id: 0
					,usuario: null
					,password: null
					,confirm_password: null
					,nombre: null
					,email: null
					,avatar: null
					,estatus: 1
					,password_reset: 0
					,rol: []
					,password_automatico: false
					,notificacion: false
					,identidad: {
						rfc: null
						,curp: null
						,sexo: null
						,nacionalidad: null
						,estado_civil: null
						,fecha_nacimiento: null
					}
					,contactos: []
				}
				this.rolesSeleccionados = [];
				this.seleccionar = false;
			}
			,abrir_modal: function(tipo) {
				if (tipo == 1) {
					this.usuario.organizacion_id = this.organizaciones[0].id;
					this.modal = 1;
				}else {
					if (this.seleccion.length > 0) {
						this.usuario = this.seleccion[0];
						this.usuario.rol = [];
						if (!this.usuario.identidad)
							this.usuario.identidad = {genero: 'femenino'};
						this.rolesSeleccionados = Array.from(this.usuario.roles);
						this.cargar_avatar();
						this.modal = 2;
					}else
						this.$helper.showMessage('Error','Tienes que seleccionar el usuario a editar', 'error', 'alert');
				}
			}
			,guardar_modal: async function(e) {
				if (e) e.preventDefault();

				for(let i=0; i<this.rolesSeleccionados.length; i++) {
					this.usuario.rol.push(this.rolesSeleccionados[i].id);
				}

				try {
					if (this.modal == 1) {
						this.usuario.organizacion = this.usuario.organizacion_id;

						for(let i=0; i<this.usuario.contactos.length; i++) {
							this.usuario.contactos[i].validado = this.usuario.contactos[i].validado ? '1' : '0';
							this.usuario.contactos[i].principal = this.usuario.contactos[i].principal ? '1' : '0';
						}

						let payload = this.$helper.clone(this.usuario);
						delete payload.avatar;
						// delete payload.contactos;

						if (!payload.password_reset || payload.password_reset == 'false')
							payload.password_reset = 0;

						if (payload.password_reset || payload.password_reset == 'true')
							payload.password_reset = 1;

						payload.notificacion = payload.notificacion == true ? 1 : 0;

						payload.password_automatico = payload.password_automatico == true ? 1 : 0;

						payload.password_reset = payload.password_reset == true ? 1 : 0;

						this.$log.info('payload',payload);
						payload = this.$helper.formDataBuilder(payload);
						this.$log.info('payload',payload);

						let input = this.$refs.file_avatar;

						if (input.files.length > 0)
							payload.append('avatar', input.files[0]);

						let res = (await api.crear_usuario(payload)).data;
					}else {
						let payload = this.$helper.clone(this.usuario);
						delete payload.avatar;
						delete payload.email;
						delete payload.organizacion;
						// delete payload.identidad;
						// delete payload.rol;
						delete payload.roles;
						delete payload.permisos;
						delete payload.contactos;

						this.$log.info('payload', payload);

						if (!payload.password_reset || payload.password_reset == 'false')
							payload.password_reset = 0;

						if (payload.password_reset || payload.password_reset == 'true')
							payload.password_reset = 1;

						payload.notificacion = payload.notificacion == true ? 1 : 0;

						payload = this.$helper.formDataBuilder(payload);
						payload.append('_method','PUT');

						let input = this.$refs.file_avatar;

						if (input.files.length > 0)
							payload.append('avatar', input.files[0]);

						await api.editar_usuario(this.usuario.id, payload);
					}
				}catch(e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e, 'Error');
					return false;
				}

				this.cancelar_modal();
				this.obtener_usuarios();
			}

			//modal roles
			,obtener_roles: async function() {
				if (this.roles.length == 0)
					this.roles = (await api.listar_roles({}, false)).data;
			}
			,abrir_roles: async function(e) {
				if (e) e.preventDefault();

				await this.obtener_roles();

				this.rol.id = this.roles_validos[0].id;

				this.modalRoles = true;
			}
			,cancelar_roles: function() {
				this.rol = {
					id: 0
					,nombre: null
				};
				this.modalRoles = false;
				this.seleccionar = false;
			}
			,agregar_roles: function(e) {
				if(e) e.preventDefault();

				let objRol = document.getElementById('roles');
				this.rol.nombre = objRol.options[objRol.selectedIndex].text;

				this.rolesSeleccionados.push(this.rol);
				this.cancelar_roles();
			}
			,quitar_rol: function(id) {
				let roles = [];

				for(let i=0; i<this.rolesSeleccionados.length; i++) {
					if (this.rolesSeleccionados[i].id != id)
						roles.push(this.rolesSeleccionados[i]);
				}

				this.rolesSeleccionados = roles;
			}
			,compilar_usuarios: async function() {
				try {
					await api.compilar_usuarios();

					this.$helper.showMessage('Exito','Los usuarios se compilaron con exito', 'success', 'notifications');
				}catch(e) {
					this.$log.info('error', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,tipo_contacto: function(tipo) {
				return Object.keys(this.contactos_tipos).indexOf(tipo) > -1 ? this.contactos_tipos[tipo] : 'Desconocido';
			}
			,editar_contacto: function(contacto) {
				this.contacto_nuevo = contacto;
				this.contacto_nuevo.accion = 2;
			}
			,agregar_contacto_nuevo: async function() {
				try {
					if (this.contacto_nuevo.accion == 1) {
						let encontrado = false;
						this.usuario.contactos.forEach(contacto => {
							if (contacto.valor == this.contacto_nuevo.valor)
								encontrado = true;
						});

						if (encontrado)
							return this.$helper.showMessage('Error','Ya existe el método de contacto indicado','error','alert');

						if (this.modal == 2) {
							let res = (await api.crear_contacto_usuario(this.usuario.id, this.contacto_nuevo)).data;

							this.usuario.contactos = res;
						}else
							this.usuario.contactos.push(this.contacto_nuevo);
					}else {
						let res = (await api.editar_contacto_usuario(this.usuario.id, this.contacto_nuevo.id, this.contacto_nuevo)).data;

						this.usuario.contactos = res;
					}

					this.contacto_nuevo = {
						tipo: 'email'
						,valor: null
						,principal: false
						,validado: false
						,accion: 1
					}
				}catch(e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,otp_modal: async function() {
				try {
					let res = (await api.enviar_otp({destinatario: this.contacto_nuevo.valor})).data

					this.$helper.showMessage('OTP enviada!','El código OTP fue enviado con exito');
					this.otp.destinatario = this.contacto_nuevo.valor;
					this.otp.mostrar = true;
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,otp_validar: async function() {
				try {
					let res = (await api.validar_otp(this.otp)).data;

					this.$helper.showMessage('Exito!','El código fue validado con éxito');
					this.contacto_nuevo.validado = 1;
					this.cancelar_otp();
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cancelar_otp: function() {
				this.otp = {
					mostrar: false
					,destinatario: null
					,codigo: null
				}
			}
			,eliminar_contacto_modal: function(contacto) {
				if (this.usuario.contactos.length == 1)
					return this.$helper.showMessage('Error','Debes dejar al menos un método de contacto','error','alert');

				if (contacto.principal == 1 || contacto.principal)
					return this.$helper.showMessage('Error','No puedes eliminar un método de contacto principal','error','alert');

				this.eliminar_contacto.id = contacto.id;
				this.eliminar_contacto.valor = contacto.valor;
				this.eliminar_contacto.principal = contacto.principal;
				this.eliminar_contacto.mostrar = true;
			}
			,eliminar_metodo_contacto: async function() {
				try {
					if (this.modal == 1) {
						let contactos = [];

						this.usuario.contactos.forEach(contacto => {
							if (contacto.valor != this.eliminar_contacto.valor)
								contactos.push(contacto);
						});

						this.usuario.contactos = contactos;
					}else {
						this.usuario.contactos = (await api.eliminar_contacto(this.usuario.id,this.eliminar_contacto.id)).data;
					}

					this.$helper.showMessage('Exito','El método de contacto se borró correctamente');
					this.eliminar_contacto = {
						mostrar: false
						,id: null
						,valor: null
					}
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,preview_avatar: function() {
				let input = this.$refs.file_avatar;
				let img = this.$refs.preview_avatar;

				if (input.files.length == 0)
					return this.$helper.showMessage('Error','Tienes que seleccionar un archivo','error','alert');

				let fileReader = new FileReader();
				fileReader.onload = function(e) {
					img.src = e.target.result;
					img.style.display = 'inline-block';
				}

				fileReader.readAsDataURL(input.files[0]);
			}
			,cargar_avatar: async function() {
				try {
					let avatar = (await api.cargar_avatar(this.usuario.id)).data;

					let img = this.$refs.preview_avatar;
					img.src = 'data:'+avatar.type+';base64,'+avatar.data;
					img.style.display = 'inline-block';
					this.$log.info('avatar cargado');
				}catch(e) {
					this.$log.info('err',e);
				}
			}
		}
		,computed: {
			roles_validos: function() {
				let roles = [];

				for(let i=0; i<this.roles.length; i++) {
					let encontrado = false;

					for(let a=0; a<this.rolesSeleccionados.length; a++) {
						if (this.roles[i].id == this.rolesSeleccionados[a].id) {
							encontrado = true;
							a = this.rolesSeleccionados.length;
						}
					}

					if (!encontrado)
						roles.push(this.roles[i]);
				}

				return roles;
			}
		}
	}
</script>

<style lang="scss">
	.list-header {
		background-color: #0781cd;
		color: #fff;
	}
	.list-item {
		.quit {
			cursor: pointer;
		}
	}
	.list-item:nth-child(2n+1) {
		background-color: #BFDCEE;
	}

	.btn-contacto {
		margin-top: 5px;
		margin-bottom: 5px;

		i {
			margin-left: 32px;
		}

		i.gg-pen {
			margin-top: 8px;
			margin-bottom: 10px;
		}

		i.gg-trash-empty {
			margin-bottom: 5px;
		}
	}

	.list_metodos_header {
		background-color: #333333;
		color: #fff;

		div {
			padding: 10px 15px;
		}
	}

	.list_metodos_contactos:nth-child(2n+1) {
		background-color: #E6E6E6;
	}

	.avatar-container {
		width: 100px !important;
		height: 100px !important;
		display: inline-block;
		position: relative;

		.avatar-img {
			width: 95%;
			margin-top: 2px;
			margin-left: 2px;
			border-radius: 50%;
			// display: none;
		}

		.avatar-control {
			display: none;
		}

		.avatar-loading {
			display: none;
			transform: translate(-50%, -50%);
			position: absolute;
			top: 50%;
			left: 50%;
		}

		.avatar-action {
			width: 100%;
			height: 100%;
			border: solid 2px #55d775;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;

			.avatar-icon {
				width: 28px;
				height: 28px;
				background-color: #55d775;
				position: absolute;
				bottom: 0;
				right: 0;

				.gg-pen {
					margin-top: 12px;
					margin-left: 10px;
					color: #fff;
				}
			}
		}

		.avatar-action:hover {
			opacity: 1;
		}
	}
</style>